import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppDispatch } from '@/redux/store/hooks';
import { setUsername } from '@slices/authSlice/authSlice';
import RouterPaths from '@router/routerPaths';

/**
 * Component wraps private routes. Redirects to login page if user is not logged.
 * @constructor
 */
const PrivateRouteWrapper: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const username = localStorage.getItem('username');
    username && dispatch(setUsername(username));
  }, []);

  return localStorage.getItem('AuthToken') && localStorage.getItem('username') ? (
    <Outlet />
  ) : (
    <Navigate to={RouterPaths.login.absolutePath} replace state={{ path: location.pathname }} />
  );
};

export default PrivateRouteWrapper;

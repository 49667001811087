import React, { lazy } from 'react';
import RouterPaths from '@router/routerPaths';
import { Route } from 'react-router-dom';

const AddMessageCode = lazy(() => import('@components/Containers/SymbolCodes/AddSymbolCode/AddSymbolCode'));
const MessageCodes = lazy(() => import('@components/Containers/SymbolCodes/SymbolCodes'));

const messageCodeRoutes = () => (
  <Route
    path={RouterPaths.messageCodes.absolutePath}
    handle={{
      breadcrumb: { label: 'breadcrumbs.messageCodes' }
    }}>
    <Route path="" element={<MessageCodes />} />
    <Route
      path={RouterPaths.messageCodes.add.relativePath}
      element={<AddMessageCode />}
      handle={{
        breadcrumb: { label: 'breadcrumbs.addMessageCodes' }
      }}
    />
  </Route>
);

export default messageCodeRoutes;

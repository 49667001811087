import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import i18n from 'i18next';
import { getEnvVariable } from '@/utils/shared/sharedFunctions';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getEnvVariable('REACT_APP_API_URL'),
    prepareHeaders: (headers, { endpoint }) => {
      headers.set('Accept-Language', i18n.language);
      if (endpoint !== 'login') headers.set('Authorization', `Bearer ${String(localStorage.getItem('AuthToken'))}`);
      // headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  tagTypes: [
    'Operators',
    'ParamTemplates',
    'Texts',
    'Sources',
    'Titles',
    'ConfigParams',
    'Messages',
    'MessageCodes',
    'STBConfigs',
    'Configurations',
    'Files',
    'Licenses'
  ],
  endpoints: () => ({})
});

import { Route } from 'react-router-dom';
import RouterPaths from '@router/routerPaths';
import React, { lazy } from 'react';

const SettingsSourcesTable = lazy(
  () => import('@components/Containers/Catalogs/CommonCategory/Tables/SettingsSourcesTable')
);
const SettingsAppTypesTable = lazy(
  () => import('@components/Containers/Catalogs/CommonCategory/Tables/SettingsAppTypesTable')
);
const SettingsDeviceTypesTable = lazy(
  () => import('@components/Containers/Catalogs/CommonCategory/Tables/SettingsDeviceTypesTable')
);
const SettingsDeviceClassesTable = lazy(
  () => import('@components/Containers/Catalogs/CommonCategory/Tables/SettingsDeviceClassesTable')
);

const SettingsLanguagesTable = lazy(
  () => import('@components/Containers/Catalogs/CommonCategory/Tables/SettingsLanguagesTable')
);
const Settings = lazy(() => import('@components/Containers/Catalogs/Catalogs'));

const settingRoutes = () => {
  return (
    <Route path={RouterPaths.settings.absolutePath}>
      <Route path="" element={<Settings />}>
        <Route path={RouterPaths.settings.languages.relativePath} element={<SettingsLanguagesTable />} />
        <Route path={RouterPaths.settings.appTypes.relativePath} element={<SettingsAppTypesTable />} />
        <Route path={RouterPaths.settings.deviceTypes.relativePath} element={<SettingsDeviceTypesTable />} />
        <Route path={RouterPaths.settings.deviceClasses.relativePath} element={<SettingsDeviceClassesTable />} />
        <Route path={RouterPaths.settings.sources.relativePath} element={<SettingsSourcesTable />} />
      </Route>
    </Route>
  );
};

export default settingRoutes;

import React, { lazy } from 'react';
import RouterPaths from '@router/routerPaths';
import { Route } from 'react-router-dom';

const Templates = lazy(() => import('@components/Containers/Templates/Templates'));
const AddTemplate = lazy(() => import('@components/Containers/Templates/AddTemplate/AddTemplate'));
const EditTemplate = lazy(() => import('@components/Containers/Templates/EditTemplate/EditTemplate'));

const parameterTemplateRoutes = () => {
  return (
    <Route
      path={RouterPaths.parameterTemplates.absolutePath}
      handle={{
        breadcrumb: { label: 'breadcrumbs.templates' }
      }}>
      <Route path="" element={<Templates />} />
      <Route
        path={RouterPaths.parameterTemplates.add.relativePath}
        element={<AddTemplate />}
        handle={{
          breadcrumb: { label: 'breadcrumbs.addTemplate' }
        }}
      />
      <Route
        path={RouterPaths.parameterTemplates.edit.relativePath}
        element={<EditTemplate />}
        handle={{
          breadcrumb: { label: (data: any) => `${data.templateCode}` }
        }}
      />
    </Route>
  );
};

export default parameterTemplateRoutes;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginApiResponse } from '@customTypes/auth/authTypes';
import { authApi } from '@/api/authApi';

interface AuthSliceState extends LoginApiResponse {
  permissions: string[];
}

const initialState: AuthSliceState = {
  username: localStorage.getItem('username'),
  token: undefined,
  permissions: []
};

export const authSlice = createSlice({
  name: 'username',
  initialState,
  reducers: {
    setUsername: (state: AuthSliceState, action: PayloadAction<string | undefined>) => {
      state.username = action.payload;
    },
    setPermissions: (state: AuthSliceState, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      localStorage.setItem('AuthToken', payload.token as string);
    });
    builder.addMatcher(authApi.endpoints.login.matchRejected, (state) => {
      localStorage.removeItem('AuthToken');
      localStorage.removeItem('username');
      state.username = initialState.username;
      state.permissions = initialState.permissions;
    });
  }
});

export const { setUsername, setPermissions } = authSlice.actions;

export default authSlice.reducer;

/* eslint-disable camelcase */
import { baseApi as api } from '@/api/baseApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    cmUmsGetVersion: build.query<CmUmsGetVersionApiResponse, CmUmsGetVersionApiArg>({
      query: () => ({ url: `/api/v1/version` })
    }),
    cmUmsNumericCodeAll: build.query<CmUmsNumericCodeAllApiResponse, CmUmsNumericCodeAllApiArg>({
      query: () => ({ url: `/cm_ums/api/v1/numeric_codes` })
    }),
    cmUmsNumericCodeUpload: build.mutation<CmUmsNumericCodeUploadApiResponse, CmUmsNumericCodeUploadApiArg>({
      query: (queryArg) => ({ url: `/cm_ums/api/v1/numeric_codes`, method: 'POST', body: queryArg.protoCodeMapArr })
    }),
    cmUmsNumericCodeGet: build.query<CmUmsNumericCodeGetApiResponse, CmUmsNumericCodeGetApiArg>({
      query: (queryArg) => ({
        url: `/cm_ums/api/v1/public/numeric_codes`,
        params: {
          operator_code: queryArg.operatorCode,
          app_type: queryArg.appType,
          source_code: queryArg.sourceCode,
          language_code: queryArg.languageCode,
          device_type: queryArg.deviceType,
          is_default_text: queryArg.isDefaultText
        }
      })
    }),
    cmUmsSymbolCodeGet: build.query<CmUmsSymbolCodeGetApiResponse, CmUmsSymbolCodeGetApiArg>({
      query: (queryArg) => ({
        url: `/cm_ums/api/v1/public/symbol_codes`,
        params: {
          operator_code: queryArg.operatorCode,
          app_type: queryArg.appType,
          fs_code: queryArg.fsCode,
          source_code: queryArg.sourceCode,
          language_code: queryArg.languageCode,
          device_type: queryArg.deviceType,
          is_default_text: queryArg.isDefaultText
        }
      })
    }),
    cmUmsSymbolCodeAll: build.query<CmUmsSymbolCodeAllApiResponse, CmUmsSymbolCodeAllApiArg>({
      query: () => ({ url: `/cm_ums/api/v1/symbol_codes` })
    }),
    cmUmsSymbolCodeUpload: build.mutation<CmUmsSymbolCodeUploadApiResponse, CmUmsSymbolCodeUploadApiArg>({
      query: (queryArg) => ({ url: `/cm_ums/api/v1/symbol_codes`, method: 'POST', body: queryArg.protoSymbolCodeArr }),
      invalidatesTags: () => [{ type: 'MessageCodes', id: 'LIST' }]
    }),
    cmUmsSymbolCodeDelete: build.mutation<CmUmsSymbolCodeDeleteApiResponse, CmUmsSymbolCodeDeleteApiArg>({
      query: (queryArg) => ({ url: `/cm_ums/api/v1/symbol_codes/${queryArg.symbolCode}`, method: 'DELETE' }),
      invalidatesTags: () => [{ type: 'MessageCodes', id: 'LIST' }]
    }),
    cmUmsSymNumCodeGet: build.query<CmUmsSymNumCodeGetApiResponse, CmUmsSymNumCodeGetApiArg>({
      query: (queryArg) => ({
        url: `/cm_ums/api/v1/symnum_codes`,
        params: {
          source: queryArg.source,
          symbol_code: queryArg.symbolCode,
          numeric_code: queryArg.numericCode,
          limit: queryArg.limit,
          offset: queryArg.offset,
          search_filter: queryArg.searchFilter
        }
      }),
      providesTags: () => [{ type: 'MessageCodes', id: 'LIST' }]
    }),
    cmUmsTextGet: build.query<CmUmsTextGetApiResponse, CmUmsTextGetApiArg>({
      query: (queryArg) => ({
        url: `/cm_ums/api/v1/texts`,
        params: {
          symbol_code: queryArg.symbolCode,
          operator_code: queryArg.operatorCode,
          app_type: queryArg.appType,
          language_code: queryArg.languageCode
        }
      }),
      providesTags: (result, error, { symbolCode }) => [{ type: 'Messages', id: symbolCode }]
    }),
    cmUmsTextMessageDelete: build.mutation<CmUmsTextMessageDeleteApiResponse, CmUmsTextMessageDeleteApiArg>({
      query: (queryArg) => ({
        url: `/cm_ums/api/v1/texts`,
        method: 'DELETE',
        params: { operator_code: queryArg.operatorCode, app_type: queryArg.appType }
      }),
      invalidatesTags: () => [{ type: 'Messages', id: 'LIST' }]
    }),
    cmUmsTextCreate: build.mutation<CmUmsTextCreateApiResponse, CmUmsTextCreateApiArg>({
      query: (queryArg) => ({ url: `/cm_ums/api/v1/texts`, method: 'POST', body: queryArg.protoTextCreateReq }),
      invalidatesTags: (result, error, { protoTextCreateReq }) => [
        { type: 'Messages', id: 'LIST' },
        { type: 'Messages', id: protoTextCreateReq.symbol_code } // handles getting a new list of message texts for operator, removal would lead to UI bugs with alternative language text addition
      ]
    }),
    cmUmsTextEdit: build.mutation<CmUmsTextEditApiResponse, CmUmsTextEditApiArg>({
      query: (queryArg) => ({ url: `/cm_ums/api/v1/texts`, method: 'PATCH', body: queryArg.protoTextEditReq }),
      invalidatesTags: (result, error, { protoTextEditReq }) => [{ type: 'Messages', id: protoTextEditReq.symbol_code }]
    }),
    cmUmsCodeTextAll: build.query<CmUmsCodeTextAllApiResponse, CmUmsCodeTextAllApiArg>({
      query: (queryArg) => ({
        url: `/cm_ums/api/v1/texts/batch`,
        params: {
          app_type: queryArg.appType,
          operator_code: queryArg.operatorCode,
          language_code: queryArg.languageCode,
          symbol_code: queryArg.symbolCode,
          numeric_code: queryArg.numericCode,
          source: queryArg.source,
          limit: queryArg.limit,
          offset: queryArg.offset,
          message_text: queryArg.messageText
        }
      }),
      providesTags: (result) =>
        result && result.texts
          ? [
              // eslint-disable-next-line camelcase
              ...result.texts.map(({ symbol_code }) => ({ type: 'Messages' as const, id: symbol_code })),
              { type: 'Messages', id: 'LIST' }
            ]
          : [{ type: 'Messages', id: 'LIST' }]
    }),
    cmUmsTextsUpload: build.mutation<CmUmsTextsUploadApiResponse, CmUmsTextsUploadApiArg>({
      query: (queryArg) => ({ url: `/cm_ums/api/v1/texts/batch`, method: 'POST', body: queryArg.protoTextsUploadReq }),
      invalidatesTags: () => [{ type: 'Messages', id: 'LIST' }]
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as cmUmsApi };
export type CmUmsGetVersionApiResponse = /** status 200 A successful response. */ ProtoVersionResponse;
export type CmUmsGetVersionApiArg = void;
export type CmUmsNumericCodeAllApiResponse = /** status 200 A successful response. */ ProtoCodeMapArr;
export type CmUmsNumericCodeAllApiArg = void;
export type CmUmsNumericCodeUploadApiResponse = /** status 200 A successful response. */ {};
export type CmUmsNumericCodeUploadApiArg = {
  protoCodeMapArr: ProtoCodeMapArr;
};
export type CmUmsNumericCodeGetApiResponse = /** status 200 A successful response. */ ProtoNumericCodeGetRes;
export type CmUmsNumericCodeGetApiArg = {
  operatorCode?: string;
  appType: string;
  sourceCode: string;
  languageCode?: string;
  deviceType?: string;
  isDefaultText?: boolean;
};
export type CmUmsSymbolCodeGetApiResponse = /** status 200 A successful response. */ ProtoSymbolCodeGetRes;
export type CmUmsSymbolCodeGetApiArg = {
  operatorCode?: string;
  appType: string;
  fsCode?: string;
  sourceCode: string;
  languageCode?: string;
  deviceType?: string;
  isDefaultText?: boolean;
};
export type CmUmsSymbolCodeAllApiResponse = /** status 200 A successful response. */ ProtoSymbolCodeArr;
export type CmUmsSymbolCodeAllApiArg = void;
export type CmUmsSymbolCodeUploadApiResponse =
  /** status 200 A successful response. */
  {} | /** status 204 A successful response. */ any;
export type CmUmsSymbolCodeUploadApiArg = {
  protoSymbolCodeArr: ProtoSymbolCodeArr;
};
export type CmUmsSymbolCodeDeleteApiResponse = /** status 200 A successful response. */ {};
export type CmUmsSymbolCodeDeleteApiArg = {
  symbolCode: string;
};
export type CmUmsSymNumCodeGetApiResponse = /** status 200 A successful response. */ ProtoSymNumCodeArr;
export type CmUmsSymNumCodeGetApiArg = {
  source?: string;
  symbolCode?: string;
  numericCode?: number;
  limit?: number;
  offset?: number;
  searchFilter?: string;
};
export type CmUmsTextGetApiResponse = /** status 200 A successful response. */ ProtoTextMessage;
export type CmUmsTextGetApiArg = {
  symbolCode?: string;
  operatorCode?: string;
  appType?: string;
  languageCode?: string;
};
export type CmUmsTextMessageDeleteApiResponse = /** status 200 A successful response. */ {};
export type CmUmsTextMessageDeleteApiArg = {
  operatorCode?: string;
  appType?: string;
};
export type CmUmsTextCreateApiResponse = /** status 200 A successful response. */ ProtoTextCreateReq;
export type CmUmsTextCreateApiArg = {
  protoTextCreateReq: ProtoTextCreateReq;
};
export type CmUmsTextEditApiResponse = /** status 200 A successful response. */ ProtoTextCreateReq;
export type CmUmsTextEditApiArg = {
  protoTextEditReq: ProtoTextEditReq;
};
export type CmUmsCodeTextAllApiResponse = /** status 200 A successful response. */ ProtoCodeGetAllRes;
export type CmUmsCodeTextAllApiArg = {
  appType?: string;
  operatorCode?: string;
  languageCode?: string;
  symbolCode?: string;
  numericCode?: number;
  source?: string;
  limit?: number;
  offset?: number;
  messageText?: string;
};
export type CmUmsTextsUploadApiResponse =
  /** status 200 A successful response. */
  {} | /** status 204 A successful response. */ any;
export type CmUmsTextsUploadApiArg = {
  protoTextsUploadReq: ProtoTextsUploadReq;
};
export type ProtoVersionResponse = {
  version?: string;
};
export type ProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type RpcStatus = {
  code?: number;
  message?: string;
  details?: ProtobufAny[];
};
export type ProtoCodeMap = {
  symbol_code?: string;
  codes?: number[];
};
export type ProtoCodeMapArr = {
  codes?: ProtoCodeMap[];
};
export type ProtoNumericCodeGetResText = {
  message?: string;
  code?: number;
  language_code?: string;
  device_type?: string;
};
export type ProtoNumericCodeGetResItem = {
  operator_code?: string;
  texts?: ProtoNumericCodeGetResText[];
};
export type ProtoNumericCodeGetRes = {
  items?: ProtoNumericCodeGetResItem[];
};
export type ProtoSymbolCodeGetResText = {
  message?: string;
  fs_code?: string;
  language_code?: string;
  device_type?: string;
};
export type ProtoSymbolCodeGetResItem = {
  operator_code?: string;
  texts?: ProtoSymbolCodeGetResText[];
};
export type ProtoSymbolCodeGetRes = {
  items?: ProtoSymbolCodeGetResItem[];
};
export type ProtoSymbolCode = {
  symbol_code: string;
  sources?: string[];
  description?: string;
};
export type ProtoSymbolCodeArr = {
  codes?: ProtoSymbolCode[];
};
export type SymNumCodeArrSymNumCode = {
  symbol_code?: string;
  sources?: string[];
  numeric_code?: number[];
  description?: string;
};
export type ProtoPagination = {
  offset?: number;
  limit?: number;
  count?: number;
};
export type ProtoSymNumCodeArr = {
  items?: SymNumCodeArrSymNumCode[];
  pagination?: ProtoPagination;
};
export type ProtoMessage = {
  message_text?: string;
  language_code: string;
  device_type?: string;
  is_default?: boolean;
};
export type ProtoTextMessage = {
  symbol_code: string;
  texts: ProtoMessage[];
};
export type ProtoTextCreateReq = {
  operator_code: string;
  app_type: string;
  symbol_code: string;
  user_message?: ProtoMessage;
};
export type ProtoTextEditReq = {
  symbol_code: string;
  operator_code: string;
  language_code: string;
  app_type: string;
  message?: string;
  is_lang_default?: boolean;
  device_type?: string;
};
export type ProtoCodeWithTexts = {
  symbol_code?: string;
  numeric_codes?: number[];
  sources?: string[];
  description?: string;
  user_messages?: ProtoMessage[];
};
export type ProtoCodeGetAllRes = {
  texts?: ProtoCodeWithTexts[];
  pagination?: ProtoPagination;
};
export type ProtoTextsUploadReq = {
  operator_code: string;
  app_type: string;
  codes: ProtoTextMessage[];
};
export const {
  useCmUmsGetVersionQuery,
  useCmUmsNumericCodeAllQuery,
  useCmUmsNumericCodeUploadMutation,
  useCmUmsNumericCodeGetQuery,
  useCmUmsSymbolCodeGetQuery,
  useCmUmsSymbolCodeAllQuery,
  useCmUmsSymbolCodeUploadMutation,
  useCmUmsSymbolCodeDeleteMutation,
  useCmUmsSymNumCodeGetQuery,
  useCmUmsTextGetQuery,
  useCmUmsTextMessageDeleteMutation,
  useCmUmsTextCreateMutation,
  useCmUmsTextEditMutation,
  useCmUmsCodeTextAllQuery,
  useCmUmsTextsUploadMutation
} = injectedRtkApi;

import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { lazily } from 'react-lazily';
import PrivateRouteWrapper from './PrivateRouteWrapper';
import RouterPaths from './routerPaths';
import operatorRoutes from '@router/routerConfig/operatorRoutes';
import parameterTemplateRoutes from './routerConfig/parameterTemplateRoutes';
import stbConfigurationRoutes from './routerConfig/stbConfigurationRoutes';
import messageCodeRoutes from './routerConfig/messageCodeRoutes';
import Layout from '@components/Layout/Layout';
import Spinner from '@components/Shared/Spinner';
import settingRoutes from '@router/routerConfig/settingRoutes';

const { NotFound404 } = lazily(() => import('@ui-kit/ui-kit'));

const Login = lazy(() => import('@components/Login/LoginPage'));

/**
 * Component manages routes for the entire app.
 * @constructor
 */
const Router: React.FC = () => {
  const routes = createRoutesFromElements(
    <Route
      path=""
      element={
        <>
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </>
      }>
      <Route path={RouterPaths.login.absolutePath} element={<Login />} />
      <Route path="" element={<PrivateRouteWrapper />}>
        <Route path="" element={<Layout />}>
          {operatorRoutes()}
          {parameterTemplateRoutes()}
          {messageCodeRoutes()}
          {stbConfigurationRoutes()}
          {settingRoutes()}
        </Route>
      </Route>
      <Route path="*" element={<NotFound404 />} /> {/* anything that starts with "/" i.e. "/any-page" */}
    </Route>
  );

  const browserRouter = createBrowserRouter(routes);

  return <RouterProvider router={browserRouter} />;
};

export default Router;

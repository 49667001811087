import { generatePath, resolvePath } from 'react-router-dom';

declare const window: any;

/**
 * Function returns passed string with first char capitalized.
 * @param text {string} - Text to be capitalized.
 * @return {string} - Text with first char capitalized.
 */
export const capitalizeFirst = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

/**
 * Function creates 'tag' object understandable by React-Select from its label.
 * @param label {string} - text of label
 * @returns {{label: string, value: string}} - created tag in React-Select format
 */
export const createTagFromLabel = (label: string) => ({
  value: label,
  label: label
});

/**
 * Function generates absolute path that can be used for react-router "navigate" function.
 * @param path {string} - Absolute path URN.
 * @param params {Record<any, any> | undefined} - Additional params that will be substituted in URN dynamic values.
 */
export const generateAbsoluteRouterPath = (path: string, params?: Record<any, any>) =>
  resolvePath(generatePath(path, params));

/**
 * Function handles Blob file downloading.
 * @param blob {Blob} - Blob file to be downloaded.
 * @param name {string} - Name of a file.
 */
export const downloadFile = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(`download`, name);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

/**
 * Function returns embedded value in object, for example
 * if const obj = {a: {b: {c: 'value'}}}, then getPathValue(obj, 'a.b.c') will return 'value'.
 * @param object {Record<string, any>} - Object with value to be extracted.
 * @param path {string} - Path for a value in object in dot notation.
 */
export const getObjectPathValue = (object: Record<string, any>, path: string) => {
  return path.split('.').reduce((acc, key) => acc?.[key], object);
};

export const getEnvVariable = (variableName: string) => window?._env_?.[variableName];
